import React, { useEffect, useState } from "react"
import Layout from "../../components-pl/layout";
import WishlistProducts from "../../components-pl/wishlist/wishlist-products";
import SEO from "../../components-pl/seo";
import { getWishListProducts } from "../../utils/functions";
import { isEmpty } from "lodash";
import Link from 'gatsby-link';

const WishList = () => {

	useEffect(() => {
	document.getElementById('lang-switch').href="/wishlist/"
	}, []);
	const [ wishList, setWishList ] = useState( getWishListProducts() );

	return (
		<>
		<SEO
	    title={ 'Lista życzeń' }
	    header={ { siteTitle: ' Shroom | Shop' } }
	  />
		<Layout>
			<div className="container my-5">
				<div style={{textAlign:'center',padding:'0 10%'}}>
				<h1 className="mt-5 woo-next-cart-heading spec-h">Lista życzeń</h1>
				<p>To jest strona z listą życzeń, możesz tu dodać pozycje na później, które zostaną magicznie zapamiętane w twoich przyszłych sesjach.
				<br /><br />

				</p>
				</div>
				{
					!isEmpty( wishList ) && wishList.productIds.length ?
						(
						<>
						<p style={{textAlign:'center'}}>Chcesz kupić te przedmioty? <Link className="text-primary" to='/pl/koszyk/'>Dodaj je do koszyka</Link>, a następnie przejdź do zamówienia.</p>
						<WishlistProducts setWishList={ setWishList }/>
						</>
						):
						 (<>
							<p style={{textAlign:'center'}}>Brak pozycji na Twojej liście życzeń <Link className="text-primary" to='/'>wróć do sklepu </Link>, następnie dodaj jakieś rzeczy.</p>
			        <div className="container mt-5" style={{height: '72vh',textAlign:'center'}}>
			          <h2 className="spec-h">Twoja lista życzeń jest pusta!</h2>
			          <p>Aby z niej skorzystać, dodaj nowe produkty ze sklepu do listy życzeń.</p>
			          <Link to="/pl/#archive">
			            <button className="btn btn-primary woo-next-large-black-btn">
			              <span className="woo-next-cart-checkout-txt">
			               Wróć do sklepu
			              </span>
			              <i className="fas fa-long-arrow-alt-right" />
			            </button>
			          </Link>
			        </div>
							</>
			      )
				}
			</div>
		</Layout>
		</>
	)
}

export default WishList;
